console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$toggle = $(".toggle"),
		$screenOverlay = $(".screen-overlay, #primary a");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

      // Get the current domain
      var currentDomain = window.location.hostname;

      // Select all anchor tags
      $('a').each(function() {
        // Get the link's href attribute
        var linkHref = $(this).attr('href');

        // Check if the link is from a different domain
        if (linkHref && linkHref.indexOf(currentDomain) === -1) {
          // Set target attribute to "_blank" to open in a new tab
          $(this).attr('target', '_blank');
        }
      });

	$toggle.on("click", function () {
		$(this).parent().toggleClass("active");
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();


    $(".sound-button").click(function() {
        if ($(this).attr('data-sound') == '1') {
            $(this).attr('data-sound', '0');
            $(this).find(".wp-block-button__link").text("ALLOW SOUND");
        } else {
            $(this).attr('data-sound', '1');
            $(this).find(".wp-block-button__link").text("DISABLE SOUND");
        }
    });

    $(document).ready(function() {
        $(".team-video").hover(
            function() { 
                this.play();

                if ($('.sound-button').attr('data-sound') == '1') {
                    this.muted = false;
                } else {
                    this.muted = true;
                }
            },
            function() { this.pause(); }
        );

        // Toggle mute/unmute on click
        // $(".team-video").click(function() {
        //     this.muted = !this.muted;
        // });
    });


});